<template>
  <div>
    <div class="shopping-mall">
        <div :class="screenWidth >= 992 ? 'main-service hidden-sm-and-down' : 'main-service-sm hidden-sm-and-down'">
            <div class="search">
                <div>
                <input
                    class="input"
                    type="text"
                    v-model="search"
                    placeholder="请输入要搜索的房源"
                />
                </div>
                <div>
                <div class="btn">搜索</div>
                </div>
            </div>
            <div class="searchcon">
                <div v-for="(item,index) in typelist" :key='index' :class="typeindex==index?'activemall':''" @click="totype(index)">{{item}}</div>
            </div>
            <div class="search-main">
                <div v-for="(item,index) in 10" :key='index'>
                    <div class="mallimg">
                        <img src="../../assets/phone/mall.png" alt="">
                    </div>
                    <div>商品名称</div>
                    <div class="color404">HK 3030</div>
                </div>
            </div>
            <div class="Hkpagination">
                <MyPagination
                    :query='query'
                    :total="1000">
                </MyPagination>
          </div>
        </div>
        <div class="hidden-sm-and-up mall-phone">
            <div class="search">
                <div>
                <input
                    class="input"
                    type="text"
                    v-model="search"
                    placeholder="请输入要搜索的房源"
                />
                </div>
                <div>
                <div class="btn">搜索</div>
                </div>
            </div>
            <div class="searchcon">
                <div v-for="(item,index) in typelist" :key='index' :class="typeindex==index?'activemall':''" @click="totype(index)">{{item}}</div>
            </div>
            <div class="search-main">
                <div v-for="(item,index) in 10" :key='index'>
                    <div class="mallimg">
                        <img src="../../assets/phone/mall.png" alt="">
                    </div>
                    <div>商品名称</div>
                    <div class="color404">HK 3030</div>
                </div>
            </div>
            <div class="Hkpagination">
                <MyPagination
                    :query='query'
                    :total="1000">
                </MyPagination>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import MyPagination from '@/components/mypagination'
export default {
    components:{
        MyPagination
    },
    data(){
        return{
        screenWidth: document.body.clientWidth,
        screenHeight: document.body.clientHeight,
            typelist:['全部','分类一','分类二','分类三','分类四'],
            typeindex:0,
            search:""
        }
    },
    mounted() {
      const that = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.documentElement.clientWidth; //实时宽度
          window.screenHeight = document.documentElement.clientHeight; //实时高度
          console.log(this.screenWidth, this.screenHeight);
          that.screenWidth = window.screenWidth;
          that.screenHeight = window.screenHeight;
        })();
      };
    },
    methods:{
        totype(e){
            this.typeindex=e
        }
    }
}
</script>

<style lang="scss">

.shopping-mall{
    background: #f7f7f7;
    padding-bottom: 20PX;
    
.Hkpagination {
  margin: 10PX auto;
  text-align: center;
}
.main-service {
  width:1176PX;
  margin: 0 auto;
}

.main-service-sm {
  width:1176PX;
  margin: 0 auto;
}

.search {
  /* margin-top: 30px; */
  padding-top: 30PX;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20PX;
  .btn {
  width: 117PX;
  height: 60PX;
  line-height: 60PX;
  text-align: center;
  border-radius: 4PX;
  color: #fff;
  font-size: 20PX;
  background-color: #fe992b;
}

.input {
  width: 1037PX;
  height: 60PX;
  border-radius: 4PX;
  background-color: #fff;
  border: none;
  font-size: 20PX;
  outline: none;
  padding: 16PX;
}
}
.searchcon{
    width: 100%;
    // height: 4PX;
    border-bottom:4PX solid rgba(#FE992B,0.3);
    display: flex;
    flex-direction: row;
    margin: 30PX 0 50PX 0;
    >div{
        width: 130PX;
        height: 60PX;
        line-height: 60PX;
        text-align: center;
        font-size: 20PX;
    }
    .activemall{
        background: #fe992b;
        color: #fff;
        border-radius: 8px 8px 0px 0px;
    }
}
.search-main{
    display: flex;
    flex-wrap: wrap;
    font-size: 24PX;
    >div{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-right: 30PX;
        margin-bottom: 40PX;
        img{
        width: 260PX;
        height: 260PX;
    }
    >div{
        margin-top: 10PX;
    }
    .color404{
        color: #F30404;
        font-weight: bold;
    }
    }
}
//移动端
.mall-phone{
    width: 335PX;
    margin: 0 auto;
    .search {
  /* margin-top: 30px; */
  padding-top: 30PX;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20PX;
  .btn {
  width: 54PX;
  height: 34PX;
  line-height: 34PX;
  text-align: center;
  border-radius: 4PX;
  color: #fff;
  font-size: 12PX;
  background-color: #fe992b;
}

.input {
  width: 274PX;
  height: 34PX;
  border-radius: 4PX;
  background-color: #fff;
  border: none;
  font-size: 12PX;
  outline: none;
  padding: 16PX;
}
}

.searchcon{
    width: 100%;
    border-bottom:3PX solid rgba(#FE992B,0.3);
    display: flex;
    flex-direction: row;
    margin:20PX 0 16PX 0;
    >div{
        width: 54PX;
        height: 24PX;
        line-height: 24PX;
        text-align: center;
        font-size: 12PX;
    }
    .activemall{
        background: #fe992b;
        color: #fff;
        border-radius: 4PX 4PX 0 0;
    }
}

.search-main{
    display: flex;
    flex-wrap: wrap;
    font-size: 12PX;
    >div{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-right: 6PX;
        margin-bottom: 10PX;
        img{
        width: 105PX;
        height: 105PX;
    }
    >div{
        margin-top: 10PX;
    }
    .color404{
        color: #F30404;
        font-weight: bold;
    }
    }
}
}
}
</style>